.volunteers .item {
    display: flex;
    border: none;
    border-radius: 0 !important;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.2s;
}

.item-selected {
    background-color: #fbf4b6 !important;
    border-bottom: 1px solid #ccc !important;
}

.updating-spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: 1s;
}

.hidden {
    z-index: -100;
    opacity: 0 !important;
}

.no-avatar {
    /*TODO: add icon*/
    background: #eee;
}

.avatar {
    border-radius: 50%;
}

.deleted {
    color: #c44 !important;
    background-color: #fee;
    text-decoration: line-through;
    overflow-wrap: anywhere;
}

.small {
    font-size: 80%;
}
