.authorization-form {
    margin: 10% auto 0;
}

.authorization-form,
.authorization-form.container {
    max-width: 450px;
}

.authorization-form .btn {
    width: 100%;
    display: block;
}

.authorization-form .registration-btn {
    margin-top: 40px;
}

.authorization-form .link {
    display: block;
    margin-top: 20px;
    text-align: center;
}

.authorization-form .privacy-policy {
    margin: 10px 0;
}

button.vk-login {
    background-color: #4680c2;
    border: none;
}

.delimiter {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid;
    line-height: 0.1em;
    margin: 25px 0 35px;
}

.delimiter-content {
    background: #fff;
    padding: 0 10px;
}
