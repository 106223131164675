/*            Menu          */
.menu {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 12px 8px 0;
    background-color: #e9ecef;
    height: 60px;
}

.menu .event {
    display: flex;
}

.menu .event-dropdown {
    width: 200px;
}

.menu .event-button {
    height: 36px;
    line-height: 16px;
    margin-left: 8px;
}

.menu-subgroup {
    display: flex;
}

.menu-subgroup .btn {
    margin-right: 5px;
}

/*            Content          */

.dispatcher-view {
    display: flex;
    width: 100%;
    overflow: hidden;
}

/*            Views - Menu          */
.dispatcher-view .side-menu {
    max-width: 300px;
    position: sticky;
    top: 0;
    height: 100%;
}

.dispatcher-view .side-menu .list-group {
    overflow-y: scroll;
    height: 85vh;
}

/*            Views - Main          */
.dispatcher-view .main {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
}

.dispatcher-view .list-group-item {
    cursor: pointer;
}

.dispatcher-view .list-group-item-success.border-transparent {
    border-color: transparent;
}
