.spinner-wrapper {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.spinner-wrapper .spinner-border {
    width: 100px;
    height: 100px;
}

.add-button {
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
}

.add-button-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 50%;
    box-shadow: #bababab0 0 0 4px 3px;
}

.item-name {
    width: 100%;
}

.list-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0 5px 5px 0 #cccccc73;
    padding-left: 15px;
    padding-right: 15px;
}

.leader {
    width: 100%;
    height: 4rem;
    content: "";
}

.search-panel {
    flex-grow: 1;
}

.flex-center {
    display: flex;
    justify-content: center;
}
