.table.movements td {
    min-width: 70px;
    position: relative;
}

.table.movements .wide-col {
    min-width: 200px;
}

.table.movements .comment-col {
    min-width: 250px;
    width: 100%;
}

.route-info {
    position: absolute;
    top: -13px;
    font-size: 85%;
    cursor: default;
}

.info-details {
    background: #fff;
    padding: 0 5px;
    color: #aaa;
}
