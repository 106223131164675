.points .point::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    cursor: pointer;
}

.row-flex {
    display: flex;
    justify-content: space-between !important;
}

.tabs {
    display: flex;
}

.tab {
    flex: 1 1;
}

.black {
    color: #000;
}

.bold {
    font-weight: bold !important;
}

select {
    padding: 0.75rem !important;
    height: unset !important;
}

.nowrap {
    white-space: nowrap;
}

.spinner-wrapper {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.spinner-wrapper .spinner-border {
    width: 100px;
    height: 100px;
}
