@import "bootstrap/scss/bootstrap";

.suggest-wrapper {
    position: relative;

    &.hide-suggest .suggest {
        display: none;
    }

    &:not(.hide-suggest) {
        .form-control {
            box-shadow: none;
        }
    }
}

.suggest {
    position: absolute;
    background: white;
    width: 100%;
    margin: 0;
    padding: 0.375rem 0;
    z-index: 1;
    border: 1px solid $gray-300;
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
}

.suggest-option {
    list-style-type: none;
    padding: 0 0.75rem;

    &:hover {
        cursor: pointer;
        background: $gray-200;
    }
}
