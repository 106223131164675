@import "../../node_modules/bootstrap/scss/bootstrap";

.table td.action_subtitle {
    background: #b9f5f0;
    color: #686262;
    font-weight: bold;
    padding: 2px 12px;
}

.table td.action_hr {
    padding: 0;
}

.collapsible-card .list-group-item {
    padding: 7px;
}

.list-group-item.group-action-label {
    background-color: theme-color-level("primary", -11);
}

.movement .load {
    margin-top: 10px;
}

.movement .load:first-child {
    margin-top: 0;
}

.card .carrier-card-header {
    height: 60px;
}
