.suggest-wrapper,
.bag-type-value {
    display: flex;
}

.suggest-wrapper {
    margin-right: 4px;

    .bag-type-suggest-option {
        list-style-type: none;
        padding: 0 0.75rem;
    }
}

.bag-type-placeholder {
    align-self: center;
    color: #b4b4b4;
}

.bag-type-suggest-modal {
    .list-group-item {
        padding: 0;
    }

    .custom-value {
        display: flex;
        align-items: center;

        .form-control {
            width: auto;
            margin-right: 6px;
        }
    }

    .empty-value {
        display: flex;
        align-items: center;

        .square {
            border-style: dashed;
        }
    }
}

.square {
    height: 25px;
    width: 25px;
    border: 1px solid #0000004b;
}

.suggest-wrapper.empty .square {
    border-style: dashed;
}
