@import "bootstrap/scss/bootstrap";

.text-input.form-control {
    border: none;
    cursor: text;
    display: inline;
    padding: 0;
}

.text-input.form-control::-webkit-input-placeholder {
    /* Chrome */
    color: #b3b3b3;
}

.text-input.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #b3b3b3;
}

.text-input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #b3b3b3;
    opacity: 1;
}

.text-input.form-control:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #b3b3b3;
    opacity: 1;
}

.text-input.invalid {
    border: 1px theme-color-level("danger") solid;
}

.text-input.form-control.saving {
    background-color: #ffc;
    transition: background-color 100ms linear;
}

.text-input.form-control {
    transition: background-color 1000ms ease-in-out;
}
