.column-value {
    text-align: right;
}

.matters-summary {
    .modal-body {
        padding: 0 0 20px;
    }

    .total {
        background: rgba(0, 0, 0, 0.05);
    }
}

.footnotes {
    margin: 16px;
}
