:root {
    --vertical-margin: 15px;
}

.header + .container {
    margin-top: var(--vertical-margin);
}

.main-content {
    margin-top: var(--vertical-margin) 20px;
}
