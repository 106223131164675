.checks-error,
.checks-warning {
    margin-right: 4px;
}

.checks-error {
    color: red;
}

.checks-warning {
    color: #d3d35b;
}
