$theme-colors: (
    "success": #56ab47,
    "primary": #1390cf,
    "info": #feed10
);

@import "bootstrap/scss/bootstrap";

.action-toggler.download {
    color: theme-color-level("success", 4);
}

.action-toggler.download:before {
    background-color: theme-color-level("success", -11);
}

.action-toggler.download:hover:before {
    background-color: theme-color-level("success", -10);
}

.action-toggler.unload {
    color: theme-color-level("danger", 4);
}

.action-toggler.unload:before {
    background-color: theme-color-level("danger", -11);
}

.action-toggler.unload:hover:before {
    background-color: theme-color-level("danger", -10);
}

.action-toggler:hover:before {
    background-color: $gray-100;
}
