.collapsible-card .card-header {
    text-align: initial;
    min-height: 50px;
}

.collapsible-card_opened .card-header .arrow {
    transform: rotate(90deg);
}

.collapsible-card .card-header .arrow {
    transition: 0.3s transform ease-in-out;
}

.collapsible-card .card-header .transparent,
.collapsible-card .card-header .transparent:focus {
    outline: none;
    border: 0;
    box-shadow: none;
    background-color: transparent;
}
