.point .table[class] thead th {
    text-align: center;
    vertical-align: middle;
}

.spinner-wrapper {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.spinner-wrapper .spinner-border {
    width: 100px;
    height: 100px;
}

.accordion {
    margin-bottom: 50px;
}

.accordion .card-header {
    cursor: pointer;
}

ol.breadcrumb {
    border-radius: 0;
    margin-bottom: 0;
}

.driver-phone {
    min-width: 130px;
}

.list-item-icon-col.col {
    max-width: 50px;
}

.plate {
    background-color: #fff;
    border-radius: 4px;
    border: solid #aaa 1px;
    /*font-family: "Courier New";*/
    padding: 1px 3px;
    white-space: nowrap;
}

.card.squared {
    border-radius: 0;
}

.card .card-header {
    min-height: 50px;
}

.full-width:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
}

.text-black {
    color: black;
}

.time {
    border-radius: 4px;
    padding: 2px;
}

.time-ahead {
    color: #0a0;
    background-color: #efe;
}

.time-behind {
    color: #a00;
    background-color: #fee;
}

.bag-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.point-matters-header.list-group-item {
    display: flex;
    align-items: center;

    .title {
        flex-grow: 1;
    }

    .btn {
        margin: 0 5px;
    }
}

.report-ok {
    border-left: 5px solid #fff !important;
    border-right: none !important;
    transition: 0.5s;
}

.report-missing {
    border-left: 5px solid #ffc107 !important;
    border-right: none !important;
    //background-color: #ffc1071a !important;
    transition: 0.5s;
}

.all-points-link {
    display: block;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
}
