.invalid .form-control {
    border-color: red;
}

.validation-message {
    color: red;
    font-size: 10px;
}

.authorization-form .info {
    margin-bottom: 10px;
}

.back-to-authorization {
    display: block;
    margin-bottom: 15px;
}
