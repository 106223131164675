.action-toggler {
    cursor: pointer;
}

.action-toggler:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.action-toggler.none {
    color: silver;
}

.action-toggler .matter {
    position: relative;
    z-index: 1;
}

.delete {
    visibility: hidden;
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;
}

.delete:hover {
    color: black;
}

.action-toggler:hover .delete {
    visibility: visible;
}

.comment {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: gray;
}

.comment:hover {
    color: black;
}

.comment.empty {
    visibility: hidden;
}

.action-toggler:hover .comment {
    visibility: visible;
}
