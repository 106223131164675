.privacy {
    font-size: 11pt;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 800px;
    padding: 10px;
    vertical-align: baseline;
}

.privacy .header {
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
}

.privacy .title {
    font-size: 13pt;
    font-weight: 700;
    padding-bottom: 0;
    padding-top: 10pt;
}

.privacy .subtitle {
    font-size: 12pt;
    font-weight: 700;
    padding-top: 16pt;
}

.privacy li {
    margin-left: 36pt;
    padding-bottom: 11pt;
    padding-left: 0;
    orphans: 2;
    widows: 2;
}

.privacy p {
    padding-top: 10px;
    orphans: 2;
    widows: 2;
}

.privacy ul {
    margin: 0;
    padding: 0;
}
