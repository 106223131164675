.user.list-group-item {
    display: flex;
    line-height: 38px;
}
.user .form-control {
    margin-left: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 0.25rem;
    float: left;
    margin-right: 15px;
}

.no-avatar {
    /*TODO: add an icon*/
    background: #eee;
}
