.new-event-modal .form-group {
    display: flex;
    align-items: center;
}

.new-event-modal .form-label {
    flex-basis: 124px;
    margin-bottom: 0;
}

.new-event-modal .DayPickerInput,
.new-event-modal .new-event-modal__event-selector {
    flex-grow: 1;
    width: auto;
    margin-left: 20px;
}
