.range-input.col {
    padding: 0 40px;
}

input[type="range"] {
    pointer-events: none;
    padding: 20px 0;
}

@mixin slider-thumb {
    pointer-events: auto;
    height: 40px;
    width: 30px;
    margin-top: -15px;
}

input[type="range"]::-webkit-slider-thumb {
    /*Webkit Browsers like Chrome and Safari*/
    @include slider-thumb;
}
input[type="range"]::-moz-range-thumb {
    /*Firefox*/
    @include slider-thumb;
}
input[type="range"]::-ms-thumb {
    /*Internet Explorer*/
    @include slider-thumb;
}

@mixin slider-track {
    height: 10px;
}

input[type="range"]::-webkit-slider-runnable-track {
    /*Webkit Browsers like Chrome and Safari*/
    @include slider-track;
}
input[type="range"]::-moz-range-track {
    /*Firefox*/
    @include slider-track;
}
input[type="range"]::-ms-track {
    /*Internet Explorer*/
    @include slider-track;
}

.no-outline {
    outline: none;
    border: none;
}
